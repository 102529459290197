import "App.css";
import React, { Suspense, useState, useRef, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import {
  ContactShadows,
  Environment,
  PresentationControls,
} from "@react-three/drei";
import Loading from "components/Loading";
import { Caneca } from "components/Caneca";
import { useIsMobile } from "hooks/useIsMobile";
import logoImage from "./img/logo-transferhouse.png";
import Spinner from "components/Spinner";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from "axios";
import { Color } from "three";

function App() {
  const isMobile = useIsMobile();
  const [rotation, setRotation] = useState({ x: 0, y: 0.2, z: 0 });
  const [customTexture, setCustomTexture] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const fullRotationCompleted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundColor: "#FFFFFF",
  });
  const [lightIntensity, setLightIntensity] = useState(1);
  const [roughness, setRoughness] = useState(0.8);
  const [metalness, setMetalness] = useState(0.1);

  const [controlsContentVisible, setControlsContentVisible] = useState(true);
  const canvasRef = useRef();
  const [gl, setGl] = useState(null);

  const handleSwitchChange = () => {
    setBackgroundStyle((prevStyle) =>
      prevStyle.backgroundColor === "white"
        ? {
          backgroundImage: `url(${logoImage})`,
          backgroundRepeat: "repeat",
          backgroundSize: "100px 100px",
          opacity: 0.7,
        }
        : {
          backgroundColor: "#FFFFFF",
        }
    );
  };

  const toggleControlsContent = () => {
    setControlsContentVisible(!controlsContentVisible);
  };

  const handleToggleBackground = () => {
    setBackgroundStyle((prevStyle) => ({
      ...prevStyle,
      backgroundImage:
        prevStyle.backgroundImage === `url(${logoImage})`
          ? ""
          : `url(${logoImage})`,
    }));
  };

  useEffect(() => {
    const canvasElement = canvasRef.current;

    if (!canvasElement) {
      console.error("No se encontró el elemento canvas.");
    }
  }, []);

  useEffect(() => {
    if (isRotating && !isRecording) {
      startRecording();
    }
  }, [isRotating, isRecording]);

  const startRecording = () => {
    if (canvasRef.current) {
      const stream = canvasRef.current.captureStream(60);

      if (stream && stream.getTracks().length > 0) {
        console.log(stream);
        const recorder = new MediaRecorder(stream, {
          mimeType: "video/webm",
          videoBitsPerSecond: 2 * 1024 * 1024,
        });

        let chunks = [];
        recorder.ondataavailable = (event) => chunks.push(event.data);
        recorder.onstop = (event) => {
          const blob = new Blob(chunks, { type: "video/webm" });
          chunks = [];

          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = `${new Date()
            .toISOString()
            .replace(/T/, " ")
            .replace(/\..+/, "")}.webm`;
          a.click();
          window.URL.revokeObjectURL(url);
        };

        setMediaRecorder(recorder);
        recorder.start();
        setIsRecording(true);
      }
    }
    setIsRotating(true);
    fullRotationCompleted.current = false;
  };

  const handleRotationComplete = () => {
    if (isRecording) {
      stopRecording();
    }
    setIsRotating(false);
    fullRotationCompleted.current = true;
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  const rotateLeft = () => {
    setRotation({ ...rotation, y: rotation.y + 0.5 });
  };

  const rotateRight = () => {
    setRotation({ ...rotation, y: rotation.y - 0.5 });
  };

  const handleNewTextureUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      setIsLoading(true);
      resizeImage(file, (blob) => {
        const url = URL.createObjectURL(blob);
        setCustomTexture(url);
        setIsLoading(false);
      });
    }
  };

  const resizeImage = async (image, callback) => {
    try {
      const formdata = new FormData();
      formdata.append("image", image, "test");

      const requestOptions = {
        method: "POST",
        body: formdata,
      };

      const response = await fetch(
        "https://prueba.transferhouse.cl:8443/add-image",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const blob = await response.blob();
      callback(blob);
    } catch (error) {
      console.error("Error during image resizing:", error);
    }
  };

  const uploadVideo = async (blob) => {
    const formData = new FormData();
    formData.append("file", blob, "video.webm");

    try {
      const response = await fetch("http://127.0.0.1:5000/convert", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const convertedBlob = await response.blob();
      const url = window.URL.createObjectURL(convertedBlob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "converted_video.mp4";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error during video conversion:", error);
    }
  };

  const getIpPublic = async () => {
    try {
      const domain = "prueba.transferhouse.cl";
      const response = await axios.get(
        `https://dns.google/resolve?name=${domain}`
      );
      const ipAddress = response.data.Answer[0].data;
      return ipAddress;
    } catch (error) {
      console.error("Error getting public IP:", error);
    }
  };

  const handleLightIntensityChange = (event) => {
    setLightIntensity(event.target.value);
  };

  const handleRoughnessChange = (event) => {
    setRoughness(event.target.value);
  };

  const handleMetalnessChange = (event) => {
    setMetalness(event.target.value);
  };

  const resetValues = () => {
    setLightIntensity(1);
    setRoughness(0.8);
    setMetalness(0.1);
  };

  const rotateAndCapture = async (angle) => {
    setRotation({ ...rotation, y: angle * (Math.PI / 180) });
    await new Promise((resolve) => setTimeout(resolve, 500)); // Un retraso más corto
    return captureImage();
  };

  const captureScreenshots = async () => {
    const angles = [0, 45, 90, 135, 180, 225, 270, 315];
    const zip = new JSZip();

    for (let angle of angles) {
      const imageData = await rotateAndCapture(angle);

      // Verifica que imageData no sea null
      if (imageData) {
        zip.file(`captura_${angle}.jpg`, imageData.split(",")[1], {
          base64: true,
        });
      }
    }

    /*  zip.generateAsync({ type: "blob" }).then((content) => {
       saveAs(content, "capturas_modelo.zip");
     }); */

     zip.generateAsync({ type: "blob" }).then(async (content) => {
      // Crear un FormData para enviar el archivo a la API
      const formData = new FormData();
    
      // Adjuntar el archivo ZIP al FormData, con el nombre "capturas_modelo.zip"
      formData.append("file", content, "capturas_modelo.zip");
    
      // Hacer la llamada a la API usando fetch
      try {
        const response = await fetch('https://prueba.transferhouse.cl:8443/upload-images', {
          method: 'PATCH', // Cambiado de PUT a POST
          body: formData,
          // Eliminamos el encabezado Content-Type, ya que FormData lo agrega automáticamente
        });
    
        if (response.ok) {
          // Convertir la respuesta en un blob y crear un enlace para descargar el archivo ZIP
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "processed_images.zip";  // Nombre del archivo a descargar
          document.body.appendChild(a);
          a.click();
          a.remove();
    
          alert("El archivo ha sido procesado y descargado correctamente.");
        } else {
          const errorMessage = await response.text(); // Intentar obtener más detalles del error
          console.error("Error en la respuesta de la API:", errorMessage);
          alert(`Error en el procesamiento del archivo: ${errorMessage}`);
        }
      } catch (error) {
        console.error("Error al enviar el archivo ZIP:", error);
        alert("Ocurrió un error al enviar el archivo ZIP. Por favor, revisa la consola para más detalles.");
      }
    });
  };

  const captureImage = () => {
    if (gl && gl.domElement) {
      // Capturar la imagen directamente desde el canvas WebGL
      const imgData = gl.domElement.toDataURL("image/jpeg");

      if (imgData) {
        return imgData;
      } else {
        console.error("No se pudo capturar la imagen.");
        return null;
      }
    } else {
      console.error("No se encontró el contexto WebGL.");
      return null;
    }
  };






  return (
    <>
      <div
        className="background-container"
        style={{
          ...backgroundStyle,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      ></div>

      <div className="App" style={backgroundStyle}>
        {isLoading && <Spinner />}

        <div className="buttons-container">
          <button
            className="control-button small-button"
            onClick={startRecording}
            disabled={isRecording}
          >
            Iniciar Grabación
          </button>
          <button
            className="control-button small-button"
            onClick={stopRecording}
            disabled={!isRecording}
          >
            Detener Grabación
          </button>

          <button className="control-button small-button" onClick={rotateLeft}>
            Girar Izquierda
          </button>
          <button
            className="control-button small-button"
            onClick={rotateRight}
          >
            Girar Derecha
          </button>

          <button
            className="control-button small-button"
            onClick={captureScreenshots}
          >
            Capturar Modelo
          </button>

          <label className="file-label control-button small-button">
            Seleccionar archivo
            <input
              type="file"
              multiple={false}
              onChange={handleNewTextureUpload}
            />
          </label>
        </div>

        <div className="controls">
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              padding: "10px",
            }}
            onClick={toggleControlsContent}
          >
            <i
              className={`bi ${controlsContentVisible ? "bi-chevron-up" : "bi-chevron-down"
                }`}
            ></i>
          </div>
          {controlsContentVisible && (
            <>
              <div>
                <label>Intensidad de la Luz:</label>
                <input
                  type="range"
                  min="0.1"
                  max="2"
                  step="0.1"
                  value={lightIntensity}
                  onChange={handleLightIntensityChange}
                />
              </div>
              <div>
                <label>Rugosidad del Material:</label>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={roughness}
                  onChange={handleRoughnessChange}
                />
              </div>
              <div>
                <label>Metalicidad del Material:</label>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={metalness}
                  onChange={handleMetalnessChange}
                />
              </div>
              <button
                className="control-button small-button"
                onClick={resetValues}
              >
                Restablecer Valores
              </button>
            </>
          )}
        </div>

        <Suspense fallback={<Loading />}>
          <Canvas
            ref={canvasRef}
            camera={{ position: [0, 0, 25], fov: 45 }}
            style={{ background: "#FFFFFF" }} // Asegúrate de que el fondo sea blanco
            gl={{ preserveDrawingBuffer: true, alpha: false }}
            onCreated={({ gl }) => {
              gl.setClearColor("#FFFFFF"); // Fuerza el color de fondo a blanco
              setGl(gl);
            }}
          >



            <pointLight
              position={[0, 10, 10]}
              color={"#ffffff"}
              intensity={lightIntensity}
              distance={50}
              decay={2}
            />
            <Environment files="/assets/hdr/potsdamer_platz_1k.hdr" />

            <PresentationControls
              global
              config={{ mass: 2, tension: 500 }}
              snap={{ mass: 4, tension: 1500 }}
              rotation={[0, 0.2, 0]}
              polar={[-Math.PI / 3, Math.PI / 3]}
              azimuth={[-Math.PI / 1.4, Math.PI / 2]}
            >
              <Caneca
                position={isMobile ? [0, -1.5, 0] : [0, 1.2, 0]}
                rotation={rotation}
                customTexture={customTexture}
                onTextureUpload={handleNewTextureUpload}
                roughness={roughness}
                metalness={metalness}
              />
            </PresentationControls>

   
          </Canvas>
        </Suspense>
      </div>
    </>
  );
}

export default App;
