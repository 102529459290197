import React, { useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { Html, useGLTF, useTexture } from "@react-three/drei";
import styles from "styles/Caneca.module.css";
import { texturesOptions } from "lib/texturesOptions";
import { useIsMobile } from "hooks/useIsMobile";

function Caneca({
  isRotating,
  onRotationComplete,
  rotation,
  customTexture,
  roughness, // Recibe el valor de rugosidad
  metalness, // Recibe el valor de metalicidad
  ...props
}) {
  const ref = useRef();
  const isMobile = useIsMobile();
  const { nodes, materials } = useGLTF("/assets/model/caneca.glb");
  const [textureActive, setTextureActive] = useState(0);

  const roughnessTexture = useTexture(
    `/assets/textures/roughness.png`,
    (texture) => {
      texture.flipY = false;
    }
  );

  const texture = useTexture(
    customTexture || `/assets/textures/${texturesOptions[textureActive].file}`,
    (texture) => {
      texture.flipY = false;
    }
  );

  /* useFrame(() => {
    if (ref.current) {
      ref.current.rotation.x = rotation.x;
      ref.current.rotation.y = rotation.y;
      ref.current.rotation.z = rotation.z;
    }
  });

  */
  let rotationCompleted = false;
  useFrame(() => {
    if (ref.current) {
      if (isRotating) {
        ref.current.rotation.y += 0.01; // Ajusta la velocidad de rotación si es necesario
        if (ref.current.rotation.y >= Math.PI * 2) {
          ref.current.rotation.y = 0;
          rotationCompleted = true;
        }
      } else {
        ref.current.rotation.x = rotation.x;
        ref.current.rotation.y = rotation.y;
        ref.current.rotation.z = rotation.z;
      }
    }

    if (rotationCompleted) {
      onRotationComplete();
      rotationCompleted = false; // Resetea el indicador de rotación completa
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes["Mug"].geometry}
        material={materials["Material"]}
        material-map={texture}
        material-roughness={roughness}
        material-roughnessMap={roughnessTexture}
        material-metalness={metalness} // Aplica la metalicidad ajustada
        ref={ref}
      >
        <Html
          position={isMobile ? [0, 8.5, 0] : [-8, 0, 0]}
          transform
          occlude
        ></Html>
      </mesh>
    </group>
  );
}

export { Caneca };
