import React, { useState, useEffect } from "react";
import "./Spinner.css"; // Asegúrate de importar tu archivo CSS aquí

const Spinner = () => {
  const [loadingText, setLoadingText] = useState("cargando");

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText((prev) => {
        // Calcular la cantidad de puntos
        const dots = prev.split(".").length;
        if (dots > 3) return "cargando";
        return prev + ".";
      });
    }, 500); // Actualiza cada 500ms

    return () => clearInterval(interval); // Limpieza al desmontar
  }, []);

  return (
    <div className="loading-background">
      <div>
        <div className="spinner"></div>
        <p className="loading-text">{loadingText}</p>
      </div>
    </div>
  );
};

export default Spinner;
